import { ReviewSchema } from './Review'
import { TokenListingSchema, TokenSchema } from './Token'
import { UserSchema } from './User'
import { z } from 'zod'
import {
  CustomerOrderSchema,
  ProductSchema,
  ShippingProfileSchema,
  StoreOrderSchema,
  StoreSchema,
} from './v2/v2'

export const SignedSchema = z.object({
  /**
   * The base64 encoded signature of the stringified {@link data}.
   */
  signature: z.string(),
  /**
   * Default is `BITCOIN_ECDSA` if no algorithm is specified.
   */
  algorithm: z.string().optional(),
})

export type Signed = z.infer<typeof SignedSchema>

export const EncryptedSchema = z.object({
  /**
   * A successful decryption will result in a JSON object that is equivalent to the {@link data} field.
   */
  data: z.string(),

  /**
   * Public key in hex string format.
   */
  pub_key: z.string(),
  /**
   * Default is `Bitcoin Signed Message` if no algorithm is specified.
   */
  algorithm: z.string().optional(),
})

export type Encrypted = z.infer<typeof EncryptedSchema>

export const SchemaDataSchema = z.union([
  ReviewSchema,
  UserSchema,
  TokenSchema,
  TokenListingSchema,
  // Added in V2
  ShippingProfileSchema,
  StoreSchema,
  CustomerOrderSchema,
  StoreOrderSchema,
  ProductSchema,
])

export type SchemaData = z.infer<typeof SchemaDataSchema>

export const SchemaTypeSchema = z.enum([
  'review',
  'user',
  'token',
  'token-listing',

  // Added in V2
  'shipping_profile',
  'store',
  'product',
  'customer_order',
  'store_order',

  // Deprecated
  'book',
  'receipt',
])

export type SchemaType = z.infer<typeof SchemaTypeSchema>

/**
 * A universal interface that encompasses any data used by Canonic. A `Schema` is always the outermost object that contains all the necessary data to read, decrypt, and verify data.
 */
export const RootSchema = z.object({
  /** A unique identifier that should persist across all versions of an object */
  id: z.string(),
  app: z.string(),
  type: SchemaTypeSchema,
  version: z.union([z.literal(1), z.literal(2)]),
  signed: SignedSchema.optional(),
  /**
   * Used for encrypting data.
   */
  encrypted: EncryptedSchema.optional(),
  /**
   * Used for un-encrypted data.
   */
  data: z.union([SchemaDataSchema, z.string()]).optional(),
})

export type Schema = z.infer<typeof RootSchema>
