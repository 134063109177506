import { BookSchema } from './Book'
import { z } from 'zod'

const TickerSchema = z.enum(['BSV', 'BTC', 'BCH'])

/** @deprecated in favor of `Order` */
const OutputSchema = z.object({
  price: BookSchema.shape.price,
  /**
   * The currency for the book's price. Should conform to the official [three-letter ISO 4217 standard](https://docs.1010data.com/1010dataReferenceManual/DataTypesAndFormats/currencyUnitCodes.html).
   */
  currency: BookSchema.shape.currency,
  /**
   * The txid containing the payment output
   */
  txid: z.string(),
  /**
   * The vout in the txid containing the payment output
   */
  vout: z.number(),
  /**
   * The blockchain on which the payment was made (BSV, BTC, BCH...)
   */
  ticker: TickerSchema,
})

export type Output = z.infer<typeof OutputSchema>

/**
 * The platform should encrypt the receipt data with their pubkey.
 * @deprecated in favor of `OrderSchema`
 */
export const ReceiptSchema = z.object({
  bsbn: BookSchema.shape.bsbn,

  /**
   * In Unix time format.
   */
  date: BookSchema.shape.date,

  book_txid: z.string(),

  quantity: z.number().nonnegative().default(1),

  outputs: z.array(OutputSchema),
})

/**
 * @deprecated in favor of `Order`
 */
export type Receipt = z.infer<typeof ReceiptSchema>
