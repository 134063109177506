import { z } from 'zod'

/**
 * All reviews are un-encrypted and should be signed with the user's `id pubkey`.
 */
export const ReviewSchema = z.object({
  product_address: z.string(),

  reviewer: z.string(),
  title: z.string(),
  body: z.string(),
  rating: z.number().int().min(1).max(5),
  /** * Date the product was reviewed, in Unix time format.
   */
  date: z.number(),
  order_id: z.string().optional(),
  token_id: z.string().optional(),
  product_txid: z.string(),
})

export type Review = z.infer<typeof ReviewSchema>
