import { z } from 'zod'

const TickerSchema = z.enum(['BSV', 'BTC', 'BCH', 'SIGNET'])

export const BFileSchema = z.object({
  type: z.string(),
  txid: z.string(),
  encoding: z.string().optional(),
  encrypted: z.boolean().optional(),
  name: z.string().optional(),
  bcat: z.boolean().optional(),
})

export const ShippingProfileSchema = z.object({
  description: z.string(),
  store_address: z.string(),
  id_pubkey: z.string(),
  address: z.string(),
  derived_path: z.string(),
  /** Array of ISO 3166-1 alpha 2 two-letter country codes */
  available_countries: z.array(z.string()),
  type: z.enum(['weight', 'price', 'flat']),
  rates: z.array(
    z.object({
      name: z.string(),
      /** Cents */
      price: z.number(),
      min_unit: z.number().optional(),
      max_unit: z.number().optional(),
    }),
  ),
})

export const StoreSchema = z.object({
  /** The primary identifier (think of this as the BSBN) */
  address: z.string(),
  id_pubkey: z.string(),
  name: z.string(),
  description: z.string(),
  /** TXID of bfile image */
  image: z.string(),
  shipping_profiles: z.array(z.union([z.string(), ShippingProfileSchema])),
  /** Tax rate that is charged on products (between 1-0) */
  tax_rate: z.number(),
})

const OrderLineItemSchema = z.object({
  product_address: z.string(),
  store_address: z.string(),
  product_txid: z.string(),
  /** Cents */
  quantity: z.number().positive(),
  options: z.record(z.string()).optional(),
  variant_id: z.string().optional(),
})

const OutputSchema = z.object({
  /** Cents */
  price: z.number().positive(),
  currency: z.string(),
  txid: z.string(),
  vout: z.number(),
  ticker: TickerSchema,
})

export const CustomerOrderSchema = z.object({
  date: z.number(),
  customer_pubkey: z.string(),
  outputs: z.array(OutputSchema),
  /** The sum of all the `outputs.price` (cents) */
  total_paid: z.number(),
  subtotal_paid: z.number(),
  shipping_paid: z.number(),
  taxes_paid: z.number(),
  /** Three-letter ISO code */
  currency: z.string().length(3),
  /** Timestamp when order was fulfilled */
  fulfilled_at: z.number().optional(),
  referral: z
    .object({
      /** In cents */
      payout: z.number(),
      paymail: z.string().email(),
    })
    .optional(),
  line_items: z.array(OrderLineItemSchema),
})

export const StoreOrderSchema = z.object({
  date: z.number(),
  customer_pubkey: z.string(),
  store_address: z.string(),
  /** The sum of all the `outputs.price` (cents) */
  total_paid: z.number(),
  subtotal_paid: z.number(),
  shipping_paid: z.number(),
  taxes_paid: z.number(),
  /** Three-letter ISO code */
  currency: z.string().length(3),
  /** Timestamp when order was fulfilled */
  fulfilled_at: z.number().optional(),
  line_items: z.array(OrderLineItemSchema),
})

export const ProductSchema = z.object({
  address: z.string(),
  name: z.string(),
  short_description: z.string().optional(),
  full_description: z.string(),
  store_address: z.string(),
  id_pubkey: z.string(),
  /** Cents */
  base_price: z.number(),
  /** Three-letter ISO 4217 code */
  currency: z.string(),
  type: z.enum(['physical', 'digital']),
  category: z.string(),
  payment_address: z.string(),
  derived_path: z.string().optional(),
  /**  Used to support backwards compatability with legacy books pre-v2. Use `derived_path` when creating new products.
   * @deprecated */
  encrypted_address_key: z.string().optional(),

  version: z.number(),
  encrypted_file_decryption_key: z.string().optional(),
  shipping_profile_address: z.string().optional(),
  published: z.boolean().optional(),
  archived_at: z.number().optional(),
  /** TXID of bfile image */
  thumbnail_image: z.string(),
  /** TXIDs of bfile images */
  images: z.array(z.string()).optional(),
  /** TXIDs of bfile files */
  files: z.array(z.string()).optional(),
  /** TXIDs of bfile files */
  sample_files: z.array(z.string()).optional(),
  weight_lbs: z.number().optional(),
  supply: z.number().optional(),
  copyright: z.string().optional(),
  release_date: z.number().optional(),
  language: z.string().optional(),
  length: z.number().optional(),
  creator: z.string().optional(),

  /**
   * An optional array of options for products with multiple variations
   * @example
   *{
   *		name: 'Color',
   *		options: [
   *			{
   *				name: 'Blue',
   *				price: 1000,
   *			},
   *			{
   *				name: 'Gold',
   *				price: 1000000,
   *				supply: 10
   *			}
   *		]
   * }
   * */
  options: z
    .array(
      z.object({
        group_id: z.string(),
        name: z.string(),
        values: z.array(
          z.object({
            id: z.string(),
            name: z.string(),
            /** An optional supply to use if the option has a different supply than the top level supply */
            supply: z.number().optional(),
          }),
        ),
      }),
    )
    .optional(),
  price_variants: z
    .array(
      z.object({
        id: z.string(),
        price: z.number(),
        options: z.array(
          z.object({
            option_group_id: z.string(),
            option_id: z.string(),
          }),
        ),
      }),
    )
    .optional(),

  /** Only relevant for revenue splitting */
  payment_addresses: z.array(
    z.object({
      paymail: z.string(),
      address: z.string().optional(),
      cut: z.number(),
    }),
  ),
  /** Product is available for preorder, with the expecation that it isn't shipping yet */
  preorder: z.boolean().optional(),
  metadata: z
    .array(z.object({ label: z.string(), value: z.string().optional() }))
    .optional(),
  /**
   * The description the token will be issued on the purchase of a book NFT
   */
  token_description: z.string().optional(),

  /**
   * Description of what you can redeem the token for if book is an NFT
   */
  token_redeemable: z.string().optional(),
  /**
   * Set if NFT token redemption requires a shipping address
   */
  token_requires_shipping: z.boolean().optional(),
})

export type Ticker = z.infer<typeof TickerSchema>
export type Store = z.infer<typeof StoreSchema>
export type ShippingProfile = z.infer<typeof ShippingProfileSchema>
export type CustomerOrder = z.infer<typeof CustomerOrderSchema>
export type StoreOrder = z.infer<typeof StoreOrderSchema>
export type Product = z.infer<typeof ProductSchema>
export type BFile = z.infer<typeof BFileSchema>
