import { z } from 'zod'

/** @deprecated in favor of `ProductSchema` */
export const BookFormatSchema = z.enum([
  'ebook',
  'audiobook',
  'paperback',
  'collectible',
])

export type BookFormat = z.infer<typeof BookFormatSchema>

/**
 * A book contains all information necessary to display, distribute, and sell a publication.
 * @deprecated in favor of `ProductSchema`
 */
export const BookSchema = z.object({
  /**
   * The public Bitcoin address and the primary identifier of the book. Derived from the author's keys.
   */
  bsbn: z.string(),
  /**
   * The title of the book.
   */
  title: z.string(),
  /**
   * The author of the book.
   */
  author: z.string(),
  /**
   * [Recommended](https://canonic.xyz/api/categories?showAll=true)
   */
  category: z.string().optional(),
  /**
   * The description of the book.
   */
  description: z.string(),
  /**
   * The book version, starting at `1`. This should be auto-incremented.
   */
  version: z.number().int(),
  /**
   * The book edition, starting at `1`. This should be editable by the author.
   */
  edition: z.number().int().optional(),

  /**
   * the maximum number of copies to be sold. sales should not be allowed if supply threshold is met.
   */
  supply: z.number().int().optional(),

  /**
   * Special text to display about the book. Commonly used for NFT and special edition releases.
   */
  special_text: z.string().optional(),
  /**
   * Date of publication, in Unix time format.
   */
  date: z.number(),
  price: z.number(),
  /**
   * The currency for the book's price. Should conform to the official [three-letter ISO 4217 standard](https://docs.1010data.com/1010dataReferenceManual/DataTypesAndFormats/currencyUnitCodes.html).
   */
  currency: z.string(),
  /**
   * The Bitcoin address of the user publishing the book. Will be used as the payment destination for all revenue if `payment_addresses` is empty.
   */
  payment_address: z.string(),
  /**
   * A list of the addresses that the revenue with be sent to, along with the cut (decimal) each address gets.
   */
  payment_addresses: z.array(
    z.object({
      paymail: z.string(),
      address: z.string().optional(),
      cut: z.number(),
    }),
  ),

  /**
   * All encrypted keys are base64 strings.
   */
  enc_keys: z.object({
    /**
     * Used by authors to publish. Encoded as a `base64` string.
     */
    bsbn: z.string(),
    /**
     * The encrypted key needed to decrypt the book files. Encoded as a `base64` string.
     */
    files: z.string().optional(),
  }),

  /**
   * All public keys are Bitcoin compressed 66-character hex strings.
   */
  pub_keys: z.object({
    /**
     * Currently not used.
     */
    bsbn: z.string(),
  }),

  format: BookFormatSchema,
  /** Usually `paperback` or `hardcover` */
  cover_type: z.string().optional(),
  /** @deprecated */
  requires_shipping: z.boolean().optional(),
  language: z.string().optional(),

  length: z.number().optional(),
  /** @deprecated Use {@link Book.length} instead */
  pages: z.number().optional(),

  copyright: z.string().optional(),
  release_date: z.string().optional(),

  /**
   * The TXID of the book's image files stored on chain.
   *
   * *First image in the array will be assumed to be the cover image*.
   */
  images: z.array(z.string()),
  /**
   * All non-audio book files associated with the book.
   */
  ebook_files: z.array(z.string()),
  /**
   * All audio book files associated with the book.
   */
  audio_files: z.array(z.string()),
  /**
   * Un-encrypted sample files.
   */
  samples: z.array(z.string()),

  /**
   * Timestamp of time book was unlisted
   */
  unlisted_at: z.number().optional(),

  /** @deprecated */
  nft: z.boolean().optional(),

  /**
   * The description the token will be issued on the purchase of a book NFT
   */
  token_description: z.string().optional(),

  /**
   * Description of what you can redeem the token for if book is an NFT
   */
  token_redeemable: z.string().optional(),
  /**
   * Set if NFT token redemption requires a shipping address
   */
  token_requires_shipping: z.boolean().optional(),

  /** Flag indicating if the book is available for preorder sales */
  preorder: z.boolean().optional(),
})

/** @deprecated in favor of `Product` */
export type Book = z.infer<typeof BookSchema>
