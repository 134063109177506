import { z } from 'zod'

/**
 * The platform should encrypt the user data with their pubkey.
 */
export const UserSchema = z.object({
  keys: z.object({
    /** The keys for the Money Button or RelayX wallet */
    third_party: z
      .object({
        paymail: z.string(),
        /**
         * 66-character hex string.
         */
        pubkey: z.string(),
        enc: z.string().optional(),
      })
      .optional(),
    id: z.object({
      paymail: z.string(),
      /**
       * 66-character hex string.
       */
      pubkey: z.string(),
    }),
  }),
  email: z.string(),
  use_canonic_fulfillment: z.boolean().optional(),

  platform_cut: z.number(),
})

export type User = z.infer<typeof UserSchema>
