import { z } from 'zod'

export const TokenSchema = z.object({
  product_address: z.string(),
  author: z.string().optional(),
  name: z.string(),
  description: z.string().optional(),
  number: z.number(),
  supply: z.number(),
  /**
   * TXID of image
   */
  image: z.string(),
  product_txid: z.string(),
  /** A decription of what the token is redeemable for. */
  redeemable: z.string().optional(),
})

export type Token = z.infer<typeof TokenSchema>

export const TokenListingSchema = z.object({
  token_id: z.string(),
  token_txid: z.string(),
  price: z.number(),
  currency: z.string(),
  seller_paymail: z.string(),
  token_sig: z.string(),
  token_pubkey: z.string().optional(),
})

export type TokenListing = z.infer<typeof TokenListingSchema>
