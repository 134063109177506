import type { Ticker } from '@canonic/lib'
import { z } from 'zod'

export const PaySessionDataSchema = z.object({
  sessionId: z.coerce.string({
    required_error: 'sessionId required',
    invalid_type_error: 'Invalid sessionId',
  }),
  callbackUrl: z
    .string({
      required_error: 'callbackUrl required',
      invalid_type_error: 'Invalid callback URL',
    })
    .url('Invalid callback URL'),
  paymail: z
    .string({
      required_error: 'paymail required',
      invalid_type_error: 'Invalid paymail',
    })
    .email('Invalid paymail'),
  outputs: z.string().optional(),
  email: z
    .string({
      required_error: 'email required',
      invalid_type_error: 'Invalid email',
    })
    .email('Invalid email'),
  product: z.string({
    required_error: 'product required',
    invalid_type_error: 'Invalid product',
  }),
  price: z.coerce
    .number({ invalid_type_error: 'Invalid price' })
    .min(0, 'price must be greater than 0')
    .optional(),
  headingText: z.string().optional(),
  subheadingText: z.string().optional(),
  hidePrice: z.coerce
    .boolean({ invalid_type_error: 'Invalid hidePrice' })
    .optional(),
  theme: z
    .enum(['dark', 'light'], {
      invalid_type_error: "Theme must either be 'dark' or 'light'",
    })
    .optional(),
  redirectUrl: z.string().url('Invalid redirect URL').optional(),
  tickers: z
    .string()
    .transform((str) => str.split(',').map((item) => item.trim()))
    .refine(
      (arr) => {
        return z.array(z.enum(['BTC', 'BCH', 'BSV', 'SIGNET'])).safeParse(arr)
          .success
      },
      { message: 'Invalid tickers ( must be either BTC, BCH, BSV, or SIGNET)' },
    )
    .optional()
    .or(z.array(z.enum(['BTC', 'BCH', 'BSV', 'SIGNET'])).optional()),
})

/** Use namespaces for any types that need to be referenced across different apps */
export namespace Pay {
  export interface PaymentOutput {
    paymail: string
    cut: number
    address?: string
  }

  export interface PaymentData {
    pricePaid: number
    priceAsked?: number
    paymentNeeded: boolean
    payments: {
      address: string
      vout: number
      txid: string
      satoshis: number
      time: number
      ticker: Ticker
      price: number
      amount_usd: number
    }[]
    outputs?: PaymentOutput[]

    prices: {
      USD: {
        [K in Ticker]?: number
      }
    }
    date: number
    sessionId: string
  }

  type Payouts = Pay.PaymentData['payments']

  export interface WalletUtxos {
    ticker: Ticker
    price: number
    currency: string
    total_usd: number
    total_sats: number
    spent_count: number
    unused_path?: string
    last_block: {
      height: number
      hash: string
      time: number
      ago: string
    }

    dust_limit: number
    sats_per_kb: number
    utxos: {
      vout: number
      txid: string
      address: string
      path?: string
      time: number
      satoshis: number
      height?: number
      amount_usd: number
    }[]
  }

  export type SessionJobData = z.infer<typeof PaySessionDataSchema> & {
    address: string
    startDate: string
    domain: string
    derived: string
    utxoData: { [ticker in Ticker]?: WalletUtxos }
    // paymentData?: Pay.PaymentData
    paymentSuccess?: Pay.PaymentData
    callbackResponse?: unknown
    payouts?: Payouts
    step?: 'Callback' | 'SplitPayment' | 'Finish'
    tickers?: Ticker[]
    skipSplitEmail?: boolean
  }
}
